import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Materiais/styled"

const MateriaisPage = () => {
  const { ebookPN } = useStaticQuery(graphql`
    query {
      ebookPN: file(
        relativePath: { eq: "ebooks/mokup-ebook-palavras-chaves-negativas.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout visibleSideBar={false}>
      <SEO
        title="Materiais para todo iniciante em Marketing Digital"
        description="Aqui você encontra os melhores ebooks e minicursos gratuitos para que você possa alavancar seus negócios."
      />
      <S.MateriaisWrapper>
        <S.MateriaisHeader>
          <S.MateriaisTitle>Materiais Grátis</S.MateriaisTitle>
          <S.MateriaisDescription>
            Abaixo quero deixar para vocês pequenos materiais como ebooks e até
            mesmo minicursos que são gratuitos e que tenho certeza que poderá
            ajudar a vocês na sua jornada. Seja acrescentando algumas
            estratégias e mindsets para o dia a dia como também ajudando com
            termos e até algumas coisas prontas para você já utilizar no seu
            negócio.
          </S.MateriaisDescription>
        </S.MateriaisHeader>
        <S.MateriaisBoxIn
          to="/paginas/ebooks/palavras-chaves-negativas"
          cover
          direction="left"
          bg="#08070e"
          duration={0.6}
        >
          <S.MateriaisImg
            fluid={ebookPN.childImageSharp.fluid}
            alt="Capa do nosso ebook de palavras chaves negativas para suas campanhas do Google ADS"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "center center",
            }}
          />
          <S.MateriaisTextBox>
            <S.MateriaisSubtitle>
              Ebook Palavras Chaves Negativas para suas Campanhas do Google Ads
            </S.MateriaisSubtitle>
            <S.MateriaisText>
              Nesse Ebook você irá encontrar uma lista de palavras chaves
              negativas que eu uso particularmente em todas as minhas campanhas
              de Google Ads. É uma lista extensa e serve muito bem para 95% dos
              infoprodutos do mercado. Não tem uma campanha minha que vai ao ar
              sem antes estar com essas palavras chaves negativadas. Então se
              você tem alguma dúvida e dificuldade em escolher palavras chaves
              para negativar em suas campanhas, pare de perder algum dinheiro
              analisando termos de pesquisa e já suba limitando as palavras que
              não vão te trazer tráfego qualificado.
            </S.MateriaisText>
            <S.MateriaisFakeButton>Saiba Mais</S.MateriaisFakeButton>
          </S.MateriaisTextBox>
        </S.MateriaisBoxIn>
      </S.MateriaisWrapper>
    </Layout>
  )
}

export default MateriaisPage
