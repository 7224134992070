import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

import AniLink from "gatsby-plugin-transition-link/AniLink"

export const MateriaisWrapper = styled.section``

export const MateriaisHeader = styled.div`
  padding: 2.5rem 0;
  background-color: var(--black_background_contrast);
`

export const MateriaisBoxOut = styled.a`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  margin-top: 5rem;
  align-items: center;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const MateriaisBoxIn = styled(AniLink)`
  text-decoration: none;
  display: flex;
  cursor: pointer;
  margin-top: 5rem;
  align-items: center;

  ${media.lessThan("large")`
    flex-direction: column;
  `}
`

export const MateriaisTextBox = styled.div`
  padding: 2rem;
  width: 70%;
  background-color: var(--black_background_contrast);
  border-left: 5px solid var(--orange);

  ${media.lessThan("large")`
    width: 100%;
  `};
`
export const MateriaisImg = styled(Img)`
  width: 30%;
  height: 250px;

  ${media.lessThan("large")`
    width: 100%;
    border-right: none;
    border-bottom: 5px solid var(--orange);
  `}
`

export const MateriaisTitle = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 1.5rem;
  color: var(--white);

  ${media.lessThan("large")`
    font-size: 2.5rem;
  `}
`

export const MateriaisSubtitle = styled.h2`
  font-size: 2rem;
  color: var(--dark_orange);
`

export const MateriaisText = styled.p`
  line-height: 1.7;
  letter-spacing: 0.069rem;
  font-weight: 300;
  margin: 1.5rem 0;
  color: var(--white);
`
export const MateriaisDescription = styled.p`
  line-height: 1.7;
  width: 80%;
  letter-spacing: 0.069rem;
  display: block;
  margin: 1rem auto;
  color: var(--white);
`

export const MateriaisFakeButton = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  color: var(--white);
  text-decoration: none;
  border: none;
  background-color: var(--dark_orange);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5;
  width: 10rem;
  text-align: center;
  height: 34px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: var(--orange);
  }
`
